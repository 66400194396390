import React from "react"
import KdpHeader from "../blocs/Header/Header"
import KdpFooter from "../blocs/Footer/Footer"
import KdpAriane from "../blocs/Ariane"

const ariane = [
  { name: 'Politique de confidentialité', href: '', current: true },
]

export default function PolitiqueConfidentialite() {
  return (
    <div className="bg-white">
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane 
          filariane={ariane}
        />
        







      </main>
      <footer>
      <KdpFooter />
      </footer>
    </div>
  );
}